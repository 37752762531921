module.exports    = {
	"ConArtist": "ConArtist",
	"About": "About",
	"Add": "Add",
	"All": "All",
	"Any": "Any",
	"Average": "Average",
	"Back": "Back",
	"Cancel": "Cancel",
	"City": "City",
	"Country": "Country",
	"Continue": "Continue",
	"Delete": "Delete",
	"Details": "Details",
	"Discard": "Discard",
	"Downvote": "Downvote",
	"Edit": "Edit",
	"Finish": "Finish",
	"Loading...": "Loading…",
	"Load more": "Load more",
	"Recommend": "Recommend",
	"Save": "Save",
	"Search": "Search",
	"Advanced Search": "Advanced Search",
	"See all": "See all",
	"Upvote": "Upvote",
	"View": "View",
	"Don't forget to verify your email!": "Don't forget to verify your email!",
	"Resend verification email": "Resend verification email",
	"Forgot Password": "Forgot Password",
	"I forgot my password": "I forgot my password",
	"Reset Password": "Reset Password",
	"Sign in": "Sign in",
	"Sign out": "Sign out",
	"Your password is incorrect": "Your password is incorrect",
	"Welcome back": "Welcome back",
	"Visit conartist.app": "Visit {action:conartist.app}",
	"Stay signed in": "Stay signed in",
	"... You're in!": "… You're in!",
	"And again": "… and again",
	"Can I get your email?": "Can I get your email?",
	"I have read and agree to the {Terms of Service} and {Privacy Policy}": "I have read and agree to the {Terms of Service} and {Privacy Policy}",
	"Let's get started!": "Let's get started!",
	"Looks good to me": "Looks good to me",
	"Now just sign at the bottom.": "Now just sign at the bottom.",
	"Pick a password. I'm not looking.": "Pick a password. I'm not looking",
	"Promise.": "Promise.",
	"Sign up": "Sign up",
	"Sign Up": "Sign Up",
	"Your password is too short": "Your password is too short",
	"Your passwords don't match": "Your passwords don't match",
	"We won't send you anything.": "We won't send you anything.",
	"We're glad you joined us.": "We're glad you joined us.",
	"What do people call you?": "What do people call you?",
	"Working...": "Working…",
	"You can change this later": "You can change this later",
	"Your name": "Your name",
	"<Email usage disclaimer>": "Your email is used only for sign-in and verification purposes.\nIt will never be shared with anyone else.\n",
	"<Name usage disclaimer>": "Your name may be visible to other artists.\nYou can use your artist handle if you like.\n",
	"<Verify Account Successful>": "Your account is now secured. Welcome to ConArtist.",
	"<Verify Account Failed>": "We couldn't verify your account right now. Maybe you should try again?",
	"Dashboard": "Dashboard",
	"Conventions": "Conventions",
	"No conventions today": "No conventions today",
	"No conventions upcoming": "No conventions upcoming",
	"{{}} conventions coming soon.": "{{}} conventions coming soon.",
	"{1} convention coming soon.": "{1} convention coming soon.",
	"{{}} {is today. Good luck!}": "{{}} {is today. Good luck!}",
	"Find more": "Find more",
	"Completed": "Completed",
	"Today": "Today",
	"Upcoming": "Upcoming",
	"You don't have any conventions starred... Why don't you go find one you like?": "You don't have any conventions bookmarked…\nWhy don't you go find one you like?\n",
	"You haven't been to any conventions yet...": "You haven't been to any conventions yet…",
	"Going": "Going",
	"Not going": "Not going",
	"There are no conventions right now": "There are no conventions right now",
	"<Convention search empty>": "There are no conventions matching your search.",
	"Address": "Address",
	"Current earnings": "Current earnings",
	"Dates": "Dates",
	"Extra info": "Extra info",
	"Hours": "Hours",
	"Expenses": "Expenses",
	"Info": "Info",
	"Net profit": "Net profit",
	"No activity for this day": "No activity for this day",
	"Revenue": "Revenue",
	"Sales": "Sales",
	"Other Sales": "Other Sales",
	"Sale": "Sale",
	"Today's hours": "Today's hours",
	"View on map": "View on map",
	"View records": "View records",
	"Website": "Website",
	"Keep it short and sweet!": "Keep it short and sweet!",
	"Share Info": "Share Info",
	"There's nothing here...": "There's nothing here…",
	"<Convention info contribution disclaimer>": "Your identity is not shared with the other artists when you contribute information. Please respect that people of all ages can read this information. Any incorrect or offensive information may be removed without notice.\n\nOnce submitted, you cannot edit what you wrote, so make sure it’s correct!\n",
	"<No convention user info>": "No one has shared any information about this convention yet…\nYou could be the first!\n",
	"Category": "Category",
	"Customers": "Customers",
	"Grouping": "Grouping",
	"Filter types": "Filter types",
	"Inventory": "Inventory",
	"Metric": "Metric",
	"{} minutes": "{} minutes",
	"Mode": "Mode",
	"Money": "Money",
	"Options": "Options",
	"Quantity": "Quantity",
	"Remaining": "Remaining",
	"Sales By Type": "Sales By Type",
	"Sales Over Time": "Sales Over Time",
	"Show sold products only": "Show sold products only",
	"Sold": "Sold",
	"Stats": "Stats",
	"Total": "Total",
	"Type": "Type",
	"<Empty records list message>": "You haven't sold anything yet",
	"<Empty no-con records list message>": "This is a place for sales you make outside of a convention.\nLooks like you haven't sold anything yet though!\n",
	"Expense saved": "Expense saved",
	"Sale saved": "Sale saved",
	"Sale deleted": "Sale deleted",
	"The amount cannot be zero": "The amount cannot be zero",
	"The amount is invalid": "The amount is invalid",
	"The price is invalid": "The price is invalid",
	"Remove": "Remove",
	"General": "General",
	"Account": "Account",
	"Change": "Change",
	"Change Language": "Change Language",
	"Currency": "Currency",
	"Language": "Language",
	"Language & Location": "Language & Location",
	"Language changed successfully": "Language changed successfully",
	"Name": "Name",
	"Report a bug/Request a feature": "Make a Suggestion",
	"Settings": "Settings",
	"Support": "Support",
	"Privacy Policy": "Privacy Policy",
	"Terms of Service": "Terms of Service",
	"Version": {
		"ios": "Version: {light:{}}",
		"web": "Version",
		"android": "Version: {light:{}}"
	},
	"Email: {}": "Email: {light:{}}",
	"Contact Support": {
		"ios": "Contact Support: {light:{}}",
		"web": "Contact Support",
		"android": "Contact Support"
	},
	"<Support description>": "Feel free to contact support for any reason! This can include requests for conventions to be added, issues with the app you need to discuss personally, or if you have a question about how something works.\n\nIf you are just making a general feature request, you can use the \"Make a Suggestion\" feature instead to gather support from other users.\n",
	"Support Us on Ko-fi": "Support Us on Ko-fi",
	"Contribute": "Contribute",
	"Donate": "Donate",
	"FAQ": "FAQ",
	"Your email is verified": "Your email is verified",
	"Your email is not verified! (Click to resend verification email)": "Your email is not verified! (Click to resend verification email)",
	"Request a Convention": "Request a Convention",
	"Change Name": "Change Name",
	"Name changed successfully": "Name changed successfully",
	"New name": "New name",
	"Change Currency": "Change Currency",
	"Currency changed successfully": "Currency changed successfully",
	"Change Email": "Change Email",
	"Confirm new email": "Confirm new email",
	"New email": "New email",
	"Email": "Email",
	"That email is already being used": "That email is already being used",
	"Verification email sent": "Verification email sent",
	"You should receive it shortly": "You should receive it shortly",
	"Your email looks wrong": "Your email looks wrong",
	"Your emails don't match": "Your emails don't match",
	"Change Password": "Change Password",
	"Current password": "Current password",
	"Confirm new password": "Confirm new password",
	"New password": "New password",
	"Password": "Password",
	"Password changed successfully": "Password changed successfully",
	"Amount": "Amount",
	"New Expense": "New Expense",
	"New expense": "New expense",
	"New sale": "New sale",
	"New Sale": "New Sale",
	"Note": "Note",
	"Nothing to say...": "Nothing to say…",
	"Time": "Time",
	"Price": "Price",
	"Prices": "Prices",
	"You haven't set any prices yet": "You haven't set any prices yet",
	"<Empty prices list message>": "You don't have anything for sale yet…\nHead over to the products page and get some items set up!\n",
	"No products yet... add one!": "No products yet… add one!",
	"Product": "Product",
	"Products": "Products",
	"SKU": "SKU",
	"You aren't selling any of these": "You aren't selling any of these",
	"<Empty products list message>": "You don't have anything for sale yet…\nEdit this page and write down what you plan to be selling!\n",
	"<First product type message>": "What kinds of things do you sell?",
	"How much do these cost?": "How much do these cost?",
	"How many": "How many",
	"New product type": "New product type",
	"New Product Type": "New Product Type",
	"New product": "New product",
	"New Product": "New Product",
	"SKU (Optional)": "SKU (Optional)",
	"Manage Prices": "Manage Prices",
	"Manage Products": "Manage Products",
	"Manage Product Types": "Manage Product Types",
	"Editing Expense": "Editing Expense",
	"Editing Sale": "Editing Sale",
	"Reorder": "Reorder",
	"This has to be a whole number!": "This has to be a whole number!",
	"This isn't a number!": "This isn't a number!",
	"This name is used twice!": "This name is used twice!",
	"This page is coming soon!": "This page is coming soon!",
	"This quantity is set twice!": "This quantity is set twice!",
	"You can charge more than that!": "You can charge more than that!",
	"You can't have less than none!": "You can't have less than none!",
	"You can't sell none!": "You can't sell none!",
	"Delete this product?": "Delete this product?",
	"Delete this product type?": "Delete this product type?",
	"This cannot be undone": "This cannot be undone",
	"Ensure the format above matches your data": "Ensure the format above matches your data",
	"Export": "Export",
	"Export Products": "Export Products",
	"ID": "ID",
	"Import": "Import",
	"Import failed: Cannot change the type of {}": "Import failed: Cannot change the type of {}",
	"Import failed: Could not find product type named {}": "Import failed: Could not find product type named {}",
	"Import failed: Could not find product with id {}": "Import failed: Could not find product with id {}",
	"Import failed: Failed to save products. Please try again later!": "Import failed: Failed to save products. Please try again later!",
	"Import failed: Unknown error": "Import failed: Unknown error",
	"Import Products": "Import Products",
	"Importing only {}": "Importing only {}",
	"Include IDs": "Include IDs",
	"Include IDs to modify exisiting products. New products can be left with an empty ID.": "Include IDs to modify exisiting products. New products can be left with an empty ID.",
	"Include titles": "Include titles",
	"Includes titles": "Includes titles",
	"Including IDs makes it much easier to import your products again later!": "Including IDs makes it much easier to import your products again later!",
	"Includes IDs": "Includes IDs",
	"Product type is specified as a column": "Product type is specified as a column",
	"Product types will be split into different files": "Product types will be split into different files",
	"Product types will be included in each row": "Product types will be included in each row",
	"Products imported successfully": "Products imported successfully",
	"Separate types": "Separate types",
	"Make a suggestion": "Make a suggestion",
	"Suggestions": "Suggestions",
	"Thanks for your feedback!": "Thanks for your feedback!",
	"There are no suggestions yet. Feel free to make a request!": "There are no suggestions yet. Feel free to make a request!",
	"Use lots of details. Make sure we know what you want!": "Use lots of details. Make sure we know what you want!",
	"<Suggestion disclaimer>": "Suggestions are to provide feedback about the ConArtist platform. If you want to request a convention to be added, there is a separate link to do this on the Settings page.\n\nYour name may be shared with other artists when you make a suggestion. Please respect that people of all ages can read this information. Any offensive text may be removed without notice.\n\nOnce submitted, you cannot edit what you wrote, so make sure it describes what you want in enough detail!\n",
	"An unknown error has occurred": "An unknown error has occurred",
	"Huh?": "Huh?",
	"It seems something went wrong.": "It seems something went wrong.",
	"Oh no...": "Oh no…",
	"Some actions might not have been saved. Please try again later": "Some actions might not have been saved. Please try again later",
	"Something is wrong...": "Something is wrong…",
	"Uh oh. You have been logged out!": "Uh oh. You have been signed out!",
	"Unknown": "Unknown",
	"You'll have to try again later... Sorry for the inconvenience.": "You'll have to try again later… Sorry for the inconvenience.",
	"Your changes were not saved. Please try again later": "Your changes were not saved. Please try again later",
	"You can't do that right now": "You can't do that right now",
	"Maybe the convention has already started?": "Maybe the convention has already started?",
	"EEE": "EEE.",
	"EEEE MMMM d, yyyy": "EEEE MMMM d, yyyy",
	"EEEE MMMM d, yyyy. h:mma": "EEEE MMMM d, yyyy. h:mma",
	"MMMM d, yyyy. h:mma": "MMMM d yyyy. h:mma",
	"MMM. d, yyyy": "MMM. d, yyyy",
	"h:mma": "h:mma",
	"h:mm": "h:mm",
	"{} - {}": "{} – {}",
	"{} {} - {}": "{} {} – {}",
	"{}x": "{}×",
	"<Splash body>": "ConArtist brings together all the things you need, streamlining your convention experience. Find conventions, manage inventory, and record sales, all in one place.",
	"<Splash title>": "The money counter for the busy tabler",
	"Chat on Discord": "Chat on Discord",
	"Download on the App Store": "Download on the App Store",
	"en-ca": "English (Canada)",
	"en-us": "English (USA)",
	"zh-Hans": "中文 (简体)"
}